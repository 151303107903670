.room-title {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: none;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;
    max-width: none !important;
}
  
.room-title h2 {
    padding: 0 27px;
    margin-bottom: 0;
}

.room-title:after {
    border-bottom: 1px solid #272727;
    flex: 1;
    content: '';
    display: block;
}

.room-title:before {
    border-bottom: 1px solid #272727;
    flex: 1;
    content: '';
}

.room-header {
    text-align: center;
    margin-bottom: 30px;
}

.room-actions {
    position: absolute;
    right: 0;
    font-size: 20px;
    padding-left: 16px;
    background: #f0f2f5;
}

.room-actions .anticon {
    cursor: pointer;
}

.room-actions .anticon + .anticon {
    margin-left:15px;
}