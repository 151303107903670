.wrap-item-type-imgs {
    padding: 30px 20px 0 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    flex-wrap: wrap;
    display: flex;
}

.item-type-img img{
    margin:5px;
    height: auto;
}
