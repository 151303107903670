.login-form-wrap {
    width: 100%;
    height: 90vh;
    display: flex;

    justify-content: center;
    /* align-self: center;    <---- REMOVE */
    align-items: center;   
}

.login-form-wrap .ant-form {
    width: 600px;
}