@import '~antd/dist/antd.css';

.ant-layout-header{
    background:#fff;
}
.ant-layout-footer {
    background:#000;
    color:#fff;
}

.ant-layout-content {
    padding: 0 50px;
    min-height: calc(100vh - 136px);
}

.ant-container {
    max-width: 1593px;
    margin: 0 auto;
    width: 100%;
}

.ant-spin-blur {
    opacity: 0;
}

.ant-input-number {
    width: 100%;
}